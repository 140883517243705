<template>
  <b-modal v-model="c_show" title="Edit Contact" hide-footer @hide="onHide">
    <b-form>
      <b-form-group label="Name">
        <b-form-input id="i-user-name" type="text" v-model="form.name" placeholder="Input contact name" />
      </b-form-group>
      <b-form-group label="Phone Number">
        <b-input-group>
          <template #prepend>
            <el-select v-model="form.country_code" filterable size="small" disabled>
              <el-option v-for="(item, index) in country_code" :key="index" :value="item.dial_code" :label="item.dial_code + ' (' +  item.name +')'"/>
            </el-select>
          </template>
          <b-form-input class="ml-2" id="i-user-phone" type="text" disabled v-model="form.phone_number" placeholder="Contact phone. eg: 8123456" />
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <label for="input-email">Label</label><br/>
        <el-select class="w-100" :loading="loading.label" :loading-text="$t('general.loading')" @change="selectedLabelChanged" multiple size="small" v-model="selected_label">
          <el-option v-for="(label) in label_list" :key="label.id" :value="label.id"
            :label="label.title"
          />
        </el-select>
      </b-form-group>
      <b-form-group :label="v.label" :key="v.key" v-for="(v,i) in custom_fields">
        <b-form-input :id="`i-${v.key}`" type="text" v-model="form.custom_data[i].value" :placeholder="`Input ${v.label}`" />
      </b-form-group>
    </b-form>
    <div class="d-flex flex-row mt-2 float-right">
      <b-button type="submit" @click="onSubmit" variant="primary" class="mr-2">{{ $t('general.submit') }}</b-button>
      <b-button type="reset" @click="onHide" variant="secondary">{{ $t('general.cancel') }}</b-button>
    </div>
  </b-modal>
</template>
<script>
import labels from '@/api/labels';
import { phone } from 'phone';
import country_code from '../../../library/full-country-code.json';

export default {
  name: 'ContactsSingleEdit',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedContact: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedContactFields: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedContactCustomData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: '',
        phone_number: '',
        country_code: '+62',
        custom_data: [],
      },
      country_code,
      c_show: false,
      loading: {
        label: false,
        field: false,
      },
      label_list: [],
      custom_fields: [],
      selected_label: [],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  methods: {
    onHide() {
      this.c_show = false;
      this.$emit('onHide');
    },
    async getLabelList() {
      this.loading.label = true;
      await labels.getList(this.activeWorkspace._id)
        .then((response) => {
          this.label_list = response.data.rows;
        });
      this.loading.label = false;
    },
    // async getCustomFields() {
    //   this.loading.field = true;
    //   await contactsApi.get_field({
    //     workspace_id: this.activeWorkspace._id,
    //   }).then((response) => {
    //     this.custom_fields = response.data.fields;
    //   });
    //   this.loading.field = false;
    // },
    selectedLabelChanged() {},
    selectedPhoneNumber() {
      const phone_number = this.selectedContact.contact.phone_number || this.selectedContact.phone_number;
      const cc = phone(`+${phone_number}`);
      console.log(cc);
      if (cc.isValid) {
        this.form.phone_number = `+${phone_number}`.replace(cc.countryCode, '');
      } else {
        this.form.phone_number = phone_number;
      }
    },
    onSubmit() {
      const phone_number = this.selectedContact.contact.phone_number || this.selectedContact.phone_number;
      const data = {
        phone: phone_number,
        name: this.form.name,
        labels_id: this.selected_label || [],
        custom_data: this.form.custom_data,
      };
      // const custom_data_filled = this.form.custom_data.filter((v) => v.value);
      // if (custom_data_filled.length > 0) {
      //   data.custom_data = custom_data_filled;
      // }
      // data.custom_data = this.form.custom_data;
      this.$emit('onSubmit', data);
    },
  },
  watch: {
    show() {
      this.c_show = this.show;
      this.custom_fields = this.selectedContactFields;
      this.custom_fields.forEach((v) => {
        const field_value = this.selectedContactCustomData[v.key];
        if (field_value) {
          this.form.custom_data.push({
            key: v.key,
            value: field_value,
          });
        } else {
          this.form.custom_data.push({
            key: v.key,
            value: '',
          });
        }
      });
      this.form.name = this.selectedContact.contact.display_name || this.selectedContact.name;
      const list_labels = this.selectedContact.labels || this.selectedContact.contact.labels;
      console.log(list_labels);
      if (list_labels) {
        this.selected_label = list_labels.map((v) => v.id);
      } else {
        this.selected_label = [];
      }
      this.selectedPhoneNumber();
      this.getLabelList();
    },
  },
};
</script>
